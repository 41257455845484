import axios from "axios";

const token = localStorage.getItem("token");
const instance = axios.create({
    baseURL: "http://192.168.3.110/msfa-livedata/public/api/",
});
//{ baseURL: 'http://festivalappapi.cph4.ch:5000/' }
instance.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";

instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// instance.defaults.headers.common['Content-Type'] = 'application/json';
// instance.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

export default instance;


// const baseURL = 'http://192.168.0.106:5500/api/'
const baseURL = 'https://newcph4v2.cph4.ch/api/'
export const axios_get = async (is_token = false, endPoint, params = "") => {
    let auth_headers = '';

    if (is_token) {
        const token = localStorage.getItem('token');
        auth_headers = { "Authorization": `${token}` }
    }
    let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...auth_headers
    };

    try {
        const instance = await axios.get(baseURL + endPoint, { headers: headers });
        let response = instance.data ? instance.data : {};
        return response
    } catch (error) {
        return error?.response?.data
    }
}

export const axios_post = async (is_token = false, endPoint, params = {}) => {
    // const token = localStorage.getItem('token');
    let auth_headers = '';
    if (is_token) {
        const token = localStorage.getItem('token');
        auth_headers = { "Authorization": `${token}` }
    }
    let headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        ...auth_headers
    };
    try {
        const instance = await axios.post(baseURL + endPoint, params, { headers: headers });
        let response = instance.data ? instance.data : {};
        return response
    } catch (error) {
        return error?.response?.data
    }
}

export const axios_post_image = async (is_token = false, endPoint, params = {}) => {
    // const token = localStorage.getItem('token');
    let auth_headers = '';
    if (is_token) {
        const token = localStorage.getItem('token');
        auth_headers = { "Authorization": `${token}` }
    }
    let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        ...auth_headers
    };
    try {
        const instance = await axios.post(baseURL + endPoint, params, { headers: headers });
        let response = instance.data ? instance.data : {};
        return response
    } catch (error) {
        return error?.response?.data
    }
}
