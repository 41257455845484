// import * as React from "react";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// // react-router-dom components
// import { Link } from "react-router-dom";
// import { useState, useEffect, useMemo } from "react";
// import axios, { axios_post } from "../../axios";
// import moment from "moment";
// import { toast, ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// // @mui material components
// import { DataGrid } from "@mui/x-data-grid";
// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Menu from "@mui/material/Menu";
// import MenuItem from "@mui/material/MenuItem";
// import IconButton from "@mui/material/IconButton";
// import MDBadge from "components/MDBadge";
// import Radio from "@mui/material/Radio";
// import RadioGroup from "@mui/material/RadioGroup";
// import FormLabel from "@mui/material/FormLabel";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import CloseIcon from "@mui/icons-material/Close";
// import PropTypes from "prop-types";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
// import { styled } from "@mui/material/styles";
// import { Autocomplete, DialogContentText, FormControl, InputLabel, Select, TextField } from "@mui/material";
// import { Snackbar, Alert } from '@mui/material';
// import { useNavigate } from "react-router-dom";
// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import { ToastMassage } from "toast";
// import jsPDF from 'jspdf';
// import MDInput from "components/MDInput";
// import { convertToWords } from "components/Number";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//     "& .MuiDialogContent-root": {
//         padding: theme.spacing(2),
//     },
//     "& .MuiDialogActions-root": {
//         padding: theme.spacing(1),
//     },
// }));

// function BootstrapDialogTitle(props) {
//     const { children, onClose, ...other } = props;

//     return (
//         <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
//             {children}
//             {onClose ? (
//                 <IconButton
//                     aria-label="close"
//                     onClick={onClose}
//                     sx={{
//                         position: "absolute",
//                         right: 8,
//                         top: 8,
//                         color: (theme) => theme.palette.grey[500],
//                     }}
//                 >
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </DialogTitle>
//     );
// }

// BootstrapDialogTitle.propTypes = {
//     children: PropTypes.node,
//     onClose: PropTypes.func.isRequired,
// };

// export default function reports() {
//     const navigate = useNavigate();
//     const [selectedValue, setSelectedValue] = useState("");
//     const [loading, setLoading] = useState(true);

//     let user_data = JSON.parse(localStorage.getItem("user_data"));
//     const handleChanged = (event) => {
//         setSelectedValue(event.target.value);
//     };

//     const [opened, setOpen] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };
//     const handleClosed = () => {
//         setOpen(false);
//     };

//     const [anchorEl, setAnchorEl] = React.useState(null);
//     const open = Boolean(anchorEl);
//     const handleClick = (event) => {
//         setAnchorEl(event.currentTarget);
//     };
//     const handleClose = () => {
//         setAnchorEl(null);
//     };

//     const [data, setData] = useState([]);
//     const [dialogbox, setdialogbox] = useState(false);
//     const [actionopen, setActionpen] = useState(false);
//     const [inactiveopen, setInactiveopen] = useState(false);
//     const [SelectedUUID, setSelectedUUID] = useState([]);
//     const [orderData, setorderData] = useState({});
//     const [openDeleteModal, setOpenDeleteModal] = useState(false);
//     const [customerList, setCustomerList] = useState([]);
//     const [formError, setFormError] = useState({});
//     const [autocompleteValue, setAutocompleteValue] = useState("");

//     const [formDatas, setFormDatas] = useState({
//         startDate: '',
//         endDate: '',
//         orderType: '',
//         customer_id: '',
//     });
//     const [formData, setFormData] = useState({
//         module: "Order",
//         action: "",
//         ids: "",
//     });
//     const handleAutocompleteChange = (event, newValue) => {
//         setAutocompleteValue(newValue);
//         console.log("newValue", newValue)
//         setFormDatas((prevData) => ({
//             ...prevData,
//             "customer_id": newValue?.user_id,
//         }));

//     };
//     const getCustomerList = async () => {
//         try {
//             const response = await axios_post(true, "customer/list");
//             setCustomerList(response.data.records);
//         } catch (error) {
//             console.error("Error fetching customer list:", error);
//         }
//     };
//     const validation = (formData) => {
//         let errors = {};

//         if (!formData.customer_id) {
//             errors.customer_id = "Customer is required";
//         }
//         if (!formData.startDate) {
//             errors.startDate = "Start Date is required";
//         }

//         if (!formData.endDate) {
//             errors.endDate = "End Date is required";
//         }

//         if (!formData.orderType) {
//             errors.orderType = "Order Type is required";
//         }
//         return errors;
//     };
//     const handleSubmit = async (event) => {
//         event.preventDefault();
//         let errors = validation(formDatas);

//         if (Object.keys(errors).length > 0) {
//             setFormError(errors);
//         } else {
//             setFormError({});
//             console.log("Form Data Submitted:", formDatas);

//             // const response = await axios_post(true, "purchase_order/store", finalPramas);
//             // if (response) {
//             //     setisSubmit(false);
//             //     if (response.status) {
//             //         ToastMassage(response.message, 'success')
//             //         navigate("/purchaseorder");

//             //     } else {
//             //         ToastMassage(response.message, 'error')
//             //     }
//             // }
//         }
//     };

//     const handleClickOpened = (orderData, invoice) => {
//         if (invoice === null) {
//             setorderData(orderData);
//             setdialogbox(true);
//         } else {
//             ToastMassage('Invoice Generated, Can not be delete, generate new Oreder.');
//         }
//     };
//     const handleClosing = () => {
//         setdialogbox(false);
//         setActionpen(false);
//         setInactiveopen(false);
//     };

//     const handleCloseDeleteModal = () => {
//         setOpenDeleteModal(false);
//     };

//     const handleClickactionOpen = () => {
//         setActionpen(true);
//     };
//     const handleClickinactiveOpen = () => {
//         setInactiveopen(true);
//     };

//     const [anchor, setAnchor] = useState(null);
//     const opening = Boolean(anchor);

//     const handleClickaction = (event) => {
//         setAnchor(event.currentTarget);
//     };

//     const handleclosedd = () => {
//         setAnchor(null);
//     };

//     const handleselection = (ids) => {
//         var selectedrow = ids.map((id) => data.find((row) => row.id === id));
//         let newUUID = [];
//         selectedrow.map((data, keys) => {
//             newUUID.push(data.uuid);
//         });
//         setSelectedUUID(newUUID);
//     };

//     const handleActiveModalSubmit = async (status) => {
//         setActionpen(false);

//         formData.ids = SelectedUUID;
//         formData.action = status;

//         const response = await axios
//             .post("global/bulk-action", formData)
//             .then((response) => {
//                 getdetails();
//                 if (status == "active") {
//                     toast.success("Mark as Active Successfully");
//                 } else {
//                     toast.success("Mark as Inactive Successfully");
//                 }
//                 setInactiveopen(false);
//             })
//             .catch((err) => {
//                 console.error(err.message);
//             });
//     };

//     const getdetails = async () => {
//         setLoading(true);
//         const response = await axios_post(true, "order/list");
//         if (response) {
//             if (response.status) {
//                 const { records } = response?.data;
//                 setData(records);
//                 setLoading(false);
//             } else {
//                 ToastMassage(response.message, 'error')
//                 setLoading(false);
//             }
//         }
//     };

//     useEffect(() => {
//         getdetails();
//         getCustomerList();
//     }, []);

//     const columns = [
//         // { field: "id", headerName: "ID", width: 70 },
//         {
//             field: "created_at",
//             headerName: "DATE",
//             width: 150,
//             sortable: true,
//             disableColumnMenu: true,
//             renderCell: (params) => moment(params?.value).format("DD MMM YYYY hh:mm A"),
//         },
//         { field: "order_number", headerName: "ORDER NUMBER", width: 150, sortable: true, disableColumnMenu: true, },
//         {
//             field: "customer",
//             headerName: "CUSTOMER NAME",
//             width: 200,
//             sortable: true,
//             disableColumnMenu: true,
//             renderCell: (params) => params?.row?.customer?.customerInfo?.customer_code + " - " + params?.value?.firstname,
//         },
//         {
//             field: "salesman",
//             headerName: "SALESMAN NAME",
//             width: 150,
//             sortable: true,
//             disableColumnMenu: true,
//             renderCell: (params) => params?.row?.salesman?.salesmanInfo?.salesman_code + " - " + params?.value?.firstname + " " + params?.value?.lastname,
//         },
//         { field: "due_date", headerName: "DUE DATE", width: 100, sortable: true, disableColumnMenu: true, },
//         { field: "grand_total", headerName: "AMOUNT", width: 100, sortable: true, disableColumnMenu: true, },
//         { field: "order_type", headerName: "ORDER TYPE", width: 100, sortable: true, disableColumnMenu: true, },
//         {
//             field: "invoice_number", headerName: "INVOICE NUMBER", width: 100, sortable: true, disableColumnMenu: true,
//             renderCell: (params) => params?.row?.invoice?.invoice_number,

//         },
//         // { field: "current_stage", headerName: "APPROVAL", width: 150, sortable: true, disableColumnMenu: false, },
//         { field: "status", headerName: "STATUS", width: 100, sortable: true, disableColumnMenu: true, },

//         {
//             field: "Action",
//             headerName: "Action",
//             width: 80,
//             sortable: false,
//             disableColumnMenu: true,
//             renderCell: (params) => {
//                 const [anchorEl, setAnchorEl] = useState(null);
//                 const open = Boolean(anchorEl);

//                 const handleClick = (event) => {
//                     setAnchorEl(event.currentTarget);
//                 };

//                 const handleClose = () => {
//                     setAnchorEl(null);
//                 };
//                 return (
//                     <>
//                         <IconButton onClick={handleClick}>
//                             <Icon fontSize="small">more_vert</Icon>
//                         </IconButton>
//                         <Menu
//                             anchorEl={anchorEl}
//                             id="action-menu"
//                             open={open}
//                             onClose={handleClose}
//                             PaperProps={{
//                                 elevation: 0,
//                                 sx: {
//                                     overflow: "visible",
//                                     filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
//                                     mt: 1.5,
//                                     "& .MuiAvatar-root": {
//                                         width: 32,
//                                         height: 32,
//                                         ml: -0.5,
//                                         mr: 1,
//                                     },
//                                     "&:before": {
//                                         content: '""',
//                                         display: "block",
//                                         position: "absolute",
//                                         top: 0,
//                                         right: 14,
//                                         width: 10,
//                                         height: 10,
//                                         bgcolor: "background.paper",
//                                         transform: "translateY(-50%) rotate(45deg)",
//                                         zIndex: 0,
//                                     },
//                                 },
//                             }}
//                             transformOrigin={{ horizontal: "right", vertical: "top" }}
//                             anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
//                         >
//                             <MenuItem onClick={() => { handleEdit(params.row.id, 'view') }}>
//                                 <Icon fontSize="small">visibility</Icon> view
//                             </MenuItem>
//                         </Menu >
//                     </>
//                 );
//             },
//         },

//     ];


//     const [searchTerm, setSearchTerm] = useState('');

//     // Memoized function to filter rows based on search term
//     const filteredRows = useMemo(() => {
//         return data.filter((row) => {
//             const customerName = row.customer?.customerInfo?.customer_code + " - " + row.customer?.firstname;
//             const salesmanName = row.salesman?.salesmanInfo?.salesman_code + " - " + row.salesman?.firstname + " " + row.salesman?.lastname;
//             const invoiceNumber = row.invoice_number;
//             const orderNumber = row.order_number;
//             return (
//                 customerName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 salesmanName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
//                 (row.due_date && row.due_date.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
//                 (row.grand_total && row.grand_total.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
//                 (row.order_type && row.order_type.toString().toLowerCase().includes(searchTerm.toLowerCase())) ||
//                 (invoiceNumber?.toLowerCase().includes(searchTerm.toLowerCase())) ||
//                 (orderNumber?.toLowerCase().includes(searchTerm.toLowerCase()))

//             );
//         });
//     }, [searchTerm]);

//     // console.log('filteredRows', filteredRows);

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <MDBox className="custome-card" pt={6} pb={3}>
//                 <Grid container spacing={6}>
//                     <Grid item xs={12}>
//                         <Card>
//                             <MDBox
//                                 mx={2}
//                                 mt={-3}
//                                 py={3}
//                                 px={2}
//                                 variant="gradient"
//                                 bgColor="info"
//                                 borderRadius="lg"
//                                 coloredShadow="info"
//                             >
//                                 <Grid container spacing={1}>
//                                     <Grid item xs={2} mr={40}>
//                                         <MDTypography variant="h6" color="white">
//                                             <Icon fontSize="small">person</Icon>
//                                             Reports
//                                         </MDTypography>
//                                     </Grid>
//                                     {SelectedUUID == "" ? (
//                                         ""
//                                     ) : (
//                                         <>
//                                             <Grid item xs={1} ml={5}>
//                                                 <MDBox>
//                                                     <MDButton
//                                                         className="bulk-button"
//                                                         aria-haspopup="true"
//                                                         onClick={handleClickaction}
//                                                         variant="gradient"
//                                                         color="light"
//                                                     >
//                                                         Bulk Actions
//                                                     </MDButton>
//                                                 </MDBox>
//                                             </Grid>
//                                         </>
//                                     )}
//                                     <Grid item xs={1}>

//                                     </Grid>
//                                 </Grid>
//                                 <Dialog
//                                     className="dialogbox"
//                                     open={actionopen}
//                                     onClose={handleClosing}
//                                     aria-labelledby="alert-dialog-title"
//                                     aria-describedby="alert-dialog-description"
//                                 >
//                                     <Icon className="icon-round" fontSize="larger" color="error">
//                                         error
//                                     </Icon>
//                                     <DialogContent dividers className="dialog-content">
//                                         <Typography gutterBottom style={{ fontSize: "20" }}>
//                                             Are you sure want to mark as active selected Records
//                                         </Typography>
//                                     </DialogContent>
//                                     <DialogActions className="Dialog-Actions">
//                                         <Button autoFocus onClick={handleClosing}>
//                                             No, mistake!
//                                         </Button>
//                                         <Button autoFocus onClick={(e) => handleActiveModalSubmit("active")}>
//                                             Yes, mark as active !
//                                         </Button>
//                                     </DialogActions>
//                                 </Dialog>
//                                 <Dialog
//                                     className="dialogbox"
//                                     open={inactiveopen}
//                                     onClose={handleClosing}
//                                     aria-labelledby="alert-dialog-title"
//                                     aria-describedby="alert-dialog-description"
//                                 >
//                                     <Icon className="icon-round" fontSize="larger" color="error">
//                                         error
//                                     </Icon>
//                                     <DialogContent dividers className="dialog-content">
//                                         <Typography gutterBottom style={{ fontSize: "20" }}>
//                                             Are you sure want to mark as inactive selected Records
//                                         </Typography>
//                                     </DialogContent>
//                                     <DialogActions className="Dialog-Actions">
//                                         <Button autoFocus onClick={handleClosing}>
//                                             No, mistake!
//                                         </Button>
//                                         <Button autoFocus onClick={(e) => handleActiveModalSubmit("inactive")}>
//                                             Yes, mark as inactive !
//                                         </Button>
//                                     </DialogActions>
//                                 </Dialog>
//                             </MDBox>
//                             <Grid container xs={12} sm={12}>
//                                 <Grid item spacing={0} xs={2}>
//                                     <InputLabel sx={{ mb: 1 }}>Start date</InputLabel>
//                                     <MDInput
//                                         type="date"
//                                         value={formDatas.startDate}
//                                         // className="small-input"
//                                         sx={{ width: 160 }}
//                                         onChange={(e) => setFormDatas({ ...formDatas, startDate: e.target.value })}
//                                     />
//                                     {formError.startDate && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.startDate}</MDTypography>}

//                                 </Grid>
//                                 <Grid item spacing={0} xs={2}>
//                                     <InputLabel sx={{ mb: 1 }}>End date</InputLabel>
//                                     <MDInput
//                                         type="date"
//                                         value={formDatas.endDate}
//                                         // className="small-input"
//                                         sx={{ width: 160 }}
//                                         onChange={(e) => setFormDatas({ ...formDatas, endDate: e.target.value })}
//                                     />
//                                     {formError.endDate && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.endDate}</MDTypography>}

//                                 </Grid>

//                                 <Grid item spacing={0} xs={3}>
//                                     <InputLabel sx={{ mb: 1 }} l>Order Type</InputLabel>
//                                     <Select
//                                         value={formDatas.orderType}
//                                         onChange={(e) => setFormDatas({ ...formDatas, orderType: e.target.value })}
//                                         // className="small-input"
//                                         sx={{ width: 200, height: 50 }}
//                                     >
//                                         <MenuItem value="sales_order">Sales Order</MenuItem>
//                                         <MenuItem value="purchase_order">Purchase Order</MenuItem>
//                                     </Select>
//                                     {formError.orderType && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.orderType}</MDTypography>}

//                                 </Grid>
//                                 <Grid item spacing={0} xs={3}>
//                                     <InputLabel sx={{ mb: 1 }} l>Customer</InputLabel>
//                                     <Autocomplete
//                                         disablePortal
//                                         id="combo-box-demo"
//                                         options={customerList}
//                                         getOptionLabel={(option) => option.customer_code || ''}
//                                         renderOption={(props, option) => (
//                                             <li {...props}>{option.customer_code}-{option?.users?.firstname} {option?.users?.lastname}</li>
//                                         )}
//                                         value={autocompleteValue}
//                                         sx={{ width: 200 }}
//                                         // className="small-input"
//                                         onChange={(event, newValue) => handleAutocompleteChange(event, newValue)}
//                                         renderInput={(params) => <TextField {...params} />}
//                                     />
//                                     {formError.customer_id && <MDTypography color="error" sx={{ fontSize: '14px', mt: "10px" }}>{formError.customer_id}</MDTypography>}

//                                 </Grid>
//                                 <Grid item spacing={0} xs={2}>
//                                     <MDButton variant="gradient" color="info" type="submit" onClick={handleSubmit}>Submit</MDButton>
//                                 </Grid>
//                             </Grid>
//                             {/* <MDBox pr={1} sx={{ textAlign: "Right" }}>
//                                 <MDInput
//                                     type="text"
//                                     // label="Order Number"
//                                     variant="outlined"
//                                     name="order_number"
//                                     sx={{ width: 300 }}
//                                     margin="normal"
//                                     placeholder="Search"
//                                     onChange={(e) => setSearchTerm(e.target.value)}
//                                 />
//                             </MDBox> */}
//                             <MDBox pt={3}>
//                                 <DataGrid
//                                     autoHeight
//                                     loading={loading}
//                                     rows={searchTerm != '' ? filteredRows : data}
//                                     columns={columns}
//                                     initialState={{
//                                         pagination: {
//                                             paginationModel: { page: 0, pageSize: 10 },
//                                         },
//                                     }}
//                                     pageSizeOptions={[5, 10, 20]}
//                                     checkboxSelection
//                                     onRowSelectionModelChange={(ids) => handleselection(ids)}
//                                     disableRowSelectionOnClick
//                                     slotProps={{
//                                         columnMenu: {
//                                             sx: {
//                                                 '& .MuiDataGrid-menuList': {
//                                                     minWidth: '200px', // Set the minimum width for the menu list
//                                                 },
//                                                 '& .MuiMenuItem-root .MuiTypography-root': {
//                                                     fontSize: '14px', // Apply the specific style to the MenuItem within DataGrid
//                                                 }
//                                             },
//                                         },
//                                     }}
//                                 />
//                             </MDBox>
//                         </Card>
//                     </Grid>
//                 </Grid>
//             </MDBox>
//         </DashboardLayout>
//     );
// }
import React from 'react';

function Role() {
  const style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
    padding: '20px', 
    margin: 0,
    textAlign: 'center'
  };

  return (
    <div style={style}>
      <h2>Coming Soon</h2>
    </div>
  );
}

export default Role;
